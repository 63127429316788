nav {
  position: fixed;
  width: 100%;
  top: 0;
  min-height: 7vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navItem {
  display: inline-block;
  position: relative;
  text-decoration: none;
  margin-right: 30px;
  font-family: Georgia;
  font-size: 15pt;
  padding: 5px;
  transition: transform 300ms;
}

@media screen and (max-width: 500px) {
  nav {
    font-size: 8pt;
  }
  .navItem {
    font-size: 9pt;
    margin-right: 10px;
  }
}

.navItem:hover {
  transform: translateY(-2px);
}

.navItem::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: #67b6bf;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  transform: scale(0);
  transform-origin: center;
}

.navItem:hover::after {
  opacity: 1;
  transform: scale(1);
}

.navItem:last-child {
  margin:0;
}

.title {
  font-family: Georgia;
  position:relative;
}

