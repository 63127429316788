.contactTitle {
    height: 30vmin;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../contact.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    background-position-y: 22%;
    margin-bottom: 30px;
}

.contactForm {
    width: 50%;
    margin: auto;
    margin-bottom: 20px;
}

.fs-frm input,
.fs-frm select,
.fs-frm textarea,
.fs-frm fieldset,
.fs-frm optgroup,
.fs-frm label,
.fs-frm #card-element:disabled {
  font-family: inherit;
  font-size: 100%;
  color: inherit;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.fs-frm label,
.fs-frm legend,
.fs-frm ::placeholder {
  font-size: .825rem;
  margin-bottom: .5rem;
  padding-top: .2rem;
  display: flex;
  align-items: baseline;
}

/* border, padding, margin, width */
.fs-frm input,
.fs-frm select,
.fs-frm textarea,
.fs-frm #card-element {
  border: 1px solid rgba(0,0,0,0.2);
  background-color: rgba(255,255,255,0.9);
  padding: .75em 1rem;
  margin-bottom: 1.5rem;
}
.fs-frm input:focus,
.fs-frm select:focus,
.fs-frm textarea:focus {
  background-color: white;
  outline-style: solid;
  outline-width: thin;
  outline-color: gray;
  outline-offset: -1px;
}
.fs-frm [type="text"],
.fs-frm [type="email"] {
  width: 100%;
}
.fs-frm [type="button"],
.fs-frm [type="submit"],
.fs-frm [type="reset"] {
  width: auto;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}
.fs-frm [type="button"]:focus,
.fs-frm [type="submit"]:focus,
.fs-frm [type="reset"]:focus {
 outline: none;
}
.fs-frm [type="submit"],
.fs-frm [type="reset"] {
  margin-bottom: 0;
}
.fs-frm select {
  text-transform: none;
}

.fs-frm [type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
  display: inline-block;
  width: auto;
  margin: 0 .5em 0 0 !important;
}

.fs-frm [type="radio"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}

/* address, locale */
.fs-frm fieldset.locale input[name="city"],
.fs-frm fieldset.locale select[name="state"],
.fs-frm fieldset.locale input[name="postal-code"] {
  display: inline;
}
.fs-frm fieldset.locale input[name="city"] {
  width: 52%;
}
.fs-frm fieldset.locale select[name="state"],
.fs-frm fieldset.locale input[name="postal-code"] {
  width: 20%;
}
.fs-frm fieldset.locale input[name="city"],
.fs-frm fieldset.locale select[name="state"] {
  margin-right: 3%;
}
