.aboutTitle {
    height: 30vmin;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../about3.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    background-position-y: 45%;
}

.availability {
    align-items: center;
    justify-content: center;
    margin: 5%;
    margin-top: 0;
}

.availabilityText {
    color: black;
    margin: 0;
    margin-left: 3rem;
    margin-right: 3rem;
}

.pictureAndText {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.about {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 5%;
    margin-top: 0;
    
}
.aboutText {
    flex: 0 0 60%;
    color: black;
}
.photo {
    flex: 0 0 30%;
    height: 70%;
    width: 350px;
    margin: 0;
    object-fit:scale-down;
}

.aboutText:first-letter {
    float:Left;
    font-size: 6rem;
    line-height: 0.65;
    margin: 0.1em 0.1em 0.2em 0;
    
}

@media (max-width: 600px) {
    .about {
        flex-direction: column;
        font-size: 13pt;
    }
    .aboutText {
        margin: 0;
        flex: 1 0;
        font-size: 13pt;
    }
    .availabilityText {
        margin: 0;
        font-size: 13pt;
    }
    .photo {
        margin-bottom: 20px;
    }
}

ul { 
    text-align: center;
}

.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    justify-content: center;
    margin: 20px;
    margin-top: 40px;
    row-gap: 20px;
}


.listItem {
    font-size: 14pt;
    font-family: Georgia;
    margin-bottom: 10px;

}

