body {
    text-align: center;
    background-color:white;
    position: relative;
}

.wrapper {
    overflow-x: hidden;
    position: relative;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  color: black;
  font-size: 15pt;
  line-height: 1.5rem;
  text-align: left;
  margin-top: 1rem;
  line-height: 2rem;
}

h2 {
  font-family: Georgia;
  margin: 10px;
  margin-top: 20px;
  font-size: 20pt;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

a:visited {
  color: inherit;
}
