.availabilityTitle {
    height: 30vmin;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../scheduling.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    background-position-y: 10%;
    margin-bottom: 30px;
}

.centeredName {
  display: flex;
  justify-content: center;
  width: 60%;
  margin: auto;
}

.days {
    display: flex;
    justify-content: center;
    margin: 3%;
    margin-bottom: 70px;
}

.days > * + * {
  margin-left: 6rem;
}
